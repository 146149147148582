// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slideshow-container {
    text-align: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  .slide {
    margin: 20px auto;
    padding: 20px;
    border: 2px solid #2c3e50;
    border-radius: 10px;
    width: 300px;
    background-color: #ecf0f1;
  }
  
  .word {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    gap: 10px; /* Adds space between the text and the button */
  }
  
  
  .translation {
    font-size: 1.2em;
    color: #34495e;
    margin-bottom: 20px;
  }
  
  .navigation button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    background-color: #2c3e50;
    color: white;
    cursor: pointer;
  }
  
  .navigation button:disabled {
    background-color: #7f8c8d;
    cursor: not-allowed;
  }
  
  .navigation button:hover:not(:disabled) {
    background-color: #34495e;
  }
  `, "",{"version":3,"sources":["webpack://./src/Slideshow.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,SAAS,EAAE,+CAA+C;EAC5D;;;EAGA;IACE,gBAAgB;IAChB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".slideshow-container {\n    text-align: center;\n    margin: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  h1 {\n    margin-bottom: 20px;\n  }\n  \n  .slide {\n    margin: 20px auto;\n    padding: 20px;\n    border: 2px solid #2c3e50;\n    border-radius: 10px;\n    width: 300px;\n    background-color: #ecf0f1;\n  }\n  \n  .word {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 1.5em;\n    font-weight: bold;\n    margin-bottom: 10px;\n    gap: 10px; /* Adds space between the text and the button */\n  }\n  \n  \n  .translation {\n    font-size: 1.2em;\n    color: #34495e;\n    margin-bottom: 20px;\n  }\n  \n  .navigation button {\n    margin: 5px;\n    padding: 10px 20px;\n    font-size: 1em;\n    border: none;\n    border-radius: 5px;\n    background-color: #2c3e50;\n    color: white;\n    cursor: pointer;\n  }\n  \n  .navigation button:disabled {\n    background-color: #7f8c8d;\n    cursor: not-allowed;\n  }\n  \n  .navigation button:hover:not(:disabled) {\n    background-color: #34495e;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lesson-container {
    margin: 20px;
    padding: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Lesson.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".lesson-container {\n    margin: 20px;\n    padding: 10px;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  th, td {\n    border: 1px solid #ccc;\n    padding: 10px;\n    text-align: left;\n  }\n  \n  th {\n    background-color: #f4f4f4;\n  }\n  \n  input[type=\"checkbox\"] {\n    width: 20px;\n    height: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

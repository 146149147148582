// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .message-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
  }
  
  .incoming {
    background-color: #e1f5fe;
    align-self: flex-start;
  }
  
  .outgoing {
    background-color: #c8e6c9;
    align-self: flex-end;
  }
  
  .input-container {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
  }
  
  .input-container input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .input-container button {
    margin-left: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #0288d1;
    color: white;
    cursor: pointer;
  }
  
  .input-container button:hover {
    background-color: #0277bd;
  }
  `, "",{"version":3,"sources":["webpack://./src/Chat.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,aAAa;IACb,0BAA0B;IAC1B,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".chat-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 100vh;\n    width: 100%;\n    max-width: 600px;\n    margin: auto;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    background-color: #f9f9f9;\n  }\n  \n  .message-list {\n    flex-grow: 1;\n    overflow-y: auto;\n    padding: 10px;\n  }\n  \n  .message {\n    margin: 5px 0;\n    padding: 10px;\n    border-radius: 5px;\n    max-width: 70%;\n  }\n  \n  .incoming {\n    background-color: #e1f5fe;\n    align-self: flex-start;\n  }\n  \n  .outgoing {\n    background-color: #c8e6c9;\n    align-self: flex-end;\n  }\n  \n  .input-container {\n    display: flex;\n    padding: 10px;\n    border-top: 1px solid #ddd;\n    background-color: #fff;\n  }\n  \n  .input-container input {\n    flex-grow: 1;\n    padding: 10px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n  }\n  \n  .input-container button {\n    margin-left: 10px;\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n    background-color: #0288d1;\n    color: white;\n    cursor: pointer;\n  }\n  \n  .input-container button:hover {\n    background-color: #0277bd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
